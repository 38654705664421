/* * {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

#root {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  > div {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
}

header {
  padding: 10px;
}

#content {
  flex: 1;
}
